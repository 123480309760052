<template>
  <b-card>
    <validation-observer
      ref="infoRules"
      v-slot="{ invalid }"
      tag="form"
    >
      <b-row>
        <b-col cols="6">
          <validation-provider
            #default="validationContext"
            name="Main Certificate"
            rules="required"
          >
            <b-form-group
              label="Certificates"
              label-for="Status"
            >
              <b-form-select
                v-model="selectedGroupId"
                :options="mainCertificate"
                placeholder="Select Main Certificate You want To Edit this Statuses."
                class="d-inline-block w-100"
                :state="getValidationState(validationContext)"
                @input="onGroupChange"
              />
            </b-form-group>
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-col>
        <b-col
          v-if="showSelectedSupCertificates"
          md="6"
        >
          <b-form-group
            label="Sup Certificates"
            label-for="Status"
          >
            <b-form-select
              id="status_id_cer"
              v-model="selectedSupCertificates"
              class="d-flex flex-column"
              :options="supCertificate"
              trim
              placeholder="Status"
              @change="onSupChange"
            />
          </b-form-group>
        </b-col>

        <b-col
          v-if="showSelectedProdect"
          md="12"
        >
          <div>
            <b-form-group label="Product Add to this Certificate">
              <v-select
                id="status_id_cer"
                v-model="selectedProduct"
                class="d-flex flex-column"
                :options="filteredOptions"
                trim
                placeholder="Select a product"
                label="text"
                :searchable="true"
                @input="addProduct"
              />
            </b-form-group>

            <ul class="d-flex flex-wrap">
              <li
                v-for="(product, index) in prodectList"
                :key="index"
                class="product-tags"
              >
                {{ product.text }}
                <button
                  type="button"
                  @click="removeProduct(product)"
                >
                  x
                </button>
              </li>
            </ul>
          </div>
        </b-col>

        <!-- Server Errors -->
        <b-col
          v-if="Object.values(errors).length > 0"
          cols="12"
        >
          <p
            v-for="(error, key, index) in errors"
            :key="index"
            class="text-danger small"
          >
            {{ error.toString() }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader && $route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addEditProToCer()"
          >
            Save Changes
          </b-button>
          <b-button
            v-if="!loader && !$route.params.id"
            variant="primary"
            class="mr-1"
            :disabled="invalid || Object.values(errors).length > 0"
            @click="addEditProToCer()"
          >
            Add
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import axios from 'axios'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VSelect from 'vue-select'
import decryptData from '@/store/auth/decryptData'

export default {
  components: {
    VSelect,
  },
  data() {
    return {
      id: this.$store.state.generalIds.id,
      required,
      loader: false,
      validationErrors: {},
      errors: {},
      mainCertificate: [],
      selectedGroupId: '',
      supCertificate: [],
      selectedSupCertificates: '',
      showSelectedSupCertificates: false,
      allProdect: [],
      showSelectedProdect: false,
      prodectList: [],
      selectedProduct: null,
    }
  },
  computed: {
    filteredOptions() {
      return this.allProdect.filter(
        product => !this.prodectList.some(selected => selected.value === product.value),
      )
    },
  },
  setup() {
    const { getValidationState } = formValidation()
    return {
      getValidationState,
    }
  },
  watch: {
    selectedGroupId: {
      handler() {
        this.onGroupChange()
      },
      immediate: true,
    },
  },
  mounted() {
    this.getAllCertificate()
    this.getAllProdect()
    setTimeout(() => {
      this.getProdectDataFromCertificate()
    }, 500)
  },
  methods: {
    getProdectDataFromCertificate() {
      if (this.$route.params.id) {
        axios.get(`certificate-with-products/${this.$route.params.id}`).then(res => {
          const { data } = decryptData(res.data.payload)
          this.selectedGroupId = data.certificate_group.id
          this.showSelectedSupCertificates = true
          this.selectedSupCertificates = data.id
          this.showSelectedProdect = true
          this.prodectList = data.product_categories.map(item => ({
            value: item.id,
            text: item.name,
          }))
        })
      }
    },
    addProduct() {
      if (this.selectedProduct) {
        const product = this.allProdect.find(opt => opt.value === this.selectedProduct.value)
        if (product) {
          this.prodectList.push(product)
          this.selectedProduct = null
        }
      }
    },
    removeProduct(product) {
      this.prodectList = this.prodectList.filter(p => p.value !== product.value)
    },
    getAllProdect() {
      axios.get('product-categories').then(res => {
        this.allProdect = decryptData(res.data.payload).data.productCategories.map(item => ({
          value: item.id,
          text: item.name,
        }))
      })
    },
    getAllCertificate() {
      axios.get('certificate').then(res => {
        this.mainCertificate = decryptData(res.data.payload).data.groups.map(item => ({
          value: item.id,
          text: `${item.name.ar} - ${item.name.en}`,
          certificates: item.certificates,
        }))
      })
    },
    onGroupChange() {
      this.showSelectedSupCertificates = true
      const group = this.mainCertificate.find(g => g.value === this.selectedGroupId)
      this.supCertificate = group ? group.certificates.map(item => ({
        value: item.id,
        text: `${item.name.ar} - ${item.name.en}`,
      })) : []
    },
    onSupChange() {
      this.showSelectedProdect = true
    },
    addEditProToCer() {
      if (this.$route.params.id) {
        this.loader = true

        const formData = new FormData()
        formData.append('_method', 'PUT')

        if (this.prodectList.length > 0) {
          this.prodectList.forEach((file, index) => formData.append(`product_ids[${index}]`, file.value))
        }

        axios
          .post(`certificates/${this.selectedSupCertificates}/products`, formData)
          .then(res => {
            if (res.status === 200 || res.status === 201) {
              this.$router.push({ name: 'certificate-product' })
              this.$toasted.show('Created Successfully', {
                position: 'top-center',
                duration: 3000,
              })
            }
          })
          .catch(error => {
            if (error.response.status) {
              const payload = decryptData(error.response.data.payload).message
              const firstArrayKey = Object.keys(payload).find(key => Array.isArray(payload[key]))
              const errorMessage = firstArrayKey ? payload[firstArrayKey][0] : 'An unknown error occurred'
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `${errorMessage}`,
                  icon: 'BellIcon',
                  variant: 'error',
                },
              })
            }
          })
          .finally(() => {
            this.loader = false
          })
      } else {
        this.loader = true

        const formData = new FormData()

        if (this.prodectList.length > 0) {
          this.prodectList.forEach((file, index) => formData.append(`product_ids[${index}]`, file.value))
        }

        axios
          .post(`certificates/${this.selectedSupCertificates}/products`, formData)
          .then(res => {
            if (res.status === 200 || res.status === 201) {
              this.$router.push({ name: 'certificate-product' })
              this.$toasted.show('Created Successfully', {
                position: 'top-center',
                duration: 3000,
              })
            }
          })
          .catch(error => {
            if (error.response.status) {
              const payload = decryptData(error.response.data.payload).message
              const firstArrayKey = Object.keys(payload).find(key => Array.isArray(payload[key]))
              const errorMessage = firstArrayKey ? payload[firstArrayKey][0] : 'An unknown error occurred'
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `${errorMessage}`,
                  icon: 'BellIcon',
                  variant: 'error',
                },
              })
            }
          })
          .finally(() => {
            this.loader = false
          })
      }
    },
  },
}
</script>

<style lang="scss">
.product-tags {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  background-color: #007bff;
  color: white;
  max-width: fit-content;
  border-radius: 20px;
  margin: 5px;
  font-size: 14px;
  position: relative;
}

.product-tags button{
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: none;
  background-color: red;
  color: white;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
}

.product-tags button:hover{
  background-color: darkred;
}
</style>
